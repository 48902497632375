import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { pageView as gtmPageView } from 'lib/services/gtm'
import { pageView as fbPageView } from 'lib/services/fb-pixel'

/**
 * This component wraps up main _app component.
 * The purpose of this wrapper is to trigger "page level"
 * tracking events.
 * ---
 * These are things like GTM pageview, or FB pixel pageview,
 * which would in traditional websites be triggered by page load.
 * In React pages are not always full load, but clientside load,
 * so traditional triggers would not work.
 */
const TrackingScripts = ({ children }) => {
  const router = useRouter()

  const routeChange = (url) => {
    gtmPageView(url)
    fbPageView()
  }

  /**
   * Triggered on first load only.
   */
  useEffect(() => {
    gtmPageView(router.asPath)
    fbPageView()
  }, [])

  /**
   * Triggered on client side route change.
   */
  useEffect(() => {
    router.events.on('routeChangeComplete', routeChange)
    return () => {
      router.events.off('routeChangeComplete', routeChange)
    }
  }, [router.events])

  return children
}

export default TrackingScripts
