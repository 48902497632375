import { unixToHuman } from 'lib/utils/dates'

const eventTypes = {
  search: 'internal_search',
  addToCart: 'add_to_cart',
  offerRequestsSend: 'send_offer_request',
  phoneNumberClicked: 'phone_number_clicked',
  eventOutboundLinksClick: 'event_outbound_links_click',
}

export const mapSearchToEvent = ({
  location,
  eventType: type,
  guests,
  dateTime: { date },
}) => ({
  event: eventTypes.search,
  location,
  day: unixToHuman(date),
  type,
  persons: guests,
})

export const mapItemToCartEvent = ({ name }) => ({
  event: eventTypes.addToCart,
  product: name,
})

export const mapItemsToOfferRequestEvent = (items) => ({
  event: eventTypes.offerRequestsSend,
  transactionProducts: items.map(({ name }) => ({
    name,
  })),
})

export const mapPhoneEvent = (phoneNumber, eventName) => ({
  event: eventTypes.phoneNumberClicked,
  name: eventName,
  number: phoneNumber,
})

export const mapEventOutboundLinksClickEvent = (url) => ({
  event: eventTypes.eventOutboundLinksClick,
  url,
})

export default {
  mapSearchToEvent,
  mapItemToCartEvent,
  mapItemsToOfferRequestEvent,
  mapEventOutboundLinksClickEvent,
}
