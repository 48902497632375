export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID

const canUseFBPixel = () => {
  if (window.fbq) {
    return true
  }

  return false
}

export const pageView = () => {
  if (canUseFBPixel()) {
    window.fbq('track', 'PageView')
  }
}

export default {
  pageView,
}
