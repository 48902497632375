import {
  mapSearchToEvent,
  mapItemToCartEvent,
  mapItemsToOfferRequestEvent,
  mapPhoneEvent,
  mapEventOutboundLinksClickEvent,
} from 'lib/utils/gtm'

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

const canUseGTM = () => {
  if (window.dataLayer) {
    return true
  }

  return false
}

export const pageView = (url) => {
  if (canUseGTM) {
    window.dataLayer.push({
      event: 'pageview',
      page: url,
    })
  }
}

export const frontPageSearchEvent = (data) => {
  if (canUseGTM) {
    const event = mapSearchToEvent(data)
    window.dataLayer.push(event)
  }
}

export const addToCartEvent = (item) => {
  if (canUseGTM) {
    const event = mapItemToCartEvent(item)
    window.dataLayer.push(event)
  }
}

export const offerRequestSubmittedEvent = (items) => {
  if (canUseGTM) {
    const event = mapItemsToOfferRequestEvent(items)
    window.dataLayer.push(event)
  }
}

export const phoneNumberClickedEvent = (phoneNumber, eventName) => {
  if (canUseGTM) {
    const event = mapPhoneEvent(phoneNumber, eventName)
    window.dataLayer.push(event)
  }
}

export const eventOutboundLinksClick = (url) => {
  if (canUseGTM) {
    const event = mapEventOutboundLinksClickEvent(url)
    window.dataLayer.push(event)
  }
}

export default {
  pageView,
  frontPageSearchEvent,
  addToCartEvent,
  offerRequestSubmittedEvent,
  phoneNumberClickedEvent,
}
