import { Provider } from 'next-auth/client'
import Script from 'next/script'
import { GTM_ID } from 'lib/services/gtm'
import { FB_PIXEL_ID } from 'lib/services/fb-pixel'
import Head from 'next/head'
import TrackingScripts from '../components/TrackingScripts'

// Global styles.
import '../styles/global.scss'

export default function App({ Component, pageProps }) {
  return (
    <>
      <Provider session={pageProps.session}>
        <TrackingScripts>
          <Component {...pageProps} />

          {/** GTM setup */}
          <Script
            id="gtm-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', '${GTM_ID}');
              `,
            }}
          />

          {/** FB Pixel setup */}
          <Script
            id="fb-pixel"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${FB_PIXEL_ID});
              `,
            }}
          />
        </TrackingScripts>
      </Provider>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.00, minimum-scale=1.00, maximum-scale=1.00, user-scalable=no"
        />
      </Head>
    </>
  )
}
