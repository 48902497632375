import fromUnixTime from 'date-fns/fromUnixTime'
import getUnixTime from 'date-fns/getUnixTime'

export const toUnix = (date) => getUnixTime(date)

export const fromUnix = (unix) => fromUnixTime(unix)

/**
 * Happen stores dates in unix
 * -> Many components show them as human-redable
 * -> Value may be empty
 */
export const unixToHuman = (unix) => {
  const date = fromUnixTime(unix)
  const result = `${date.getDate()}.${
    date.getMonth() + 1
  }.${date.getFullYear()}`

  if (result === '1.1.1970' || result === '31.12.1969') {
    return ''
  }

  return result
}

export default {
  toUnix,
  fromUnix,
  unixToHuman,
}
